<!-- RoomModal.vue -->
<template>
  <div v-if="roomData.room_url">
    <el-dialog v-model="isVisible" title="获取房间数据成功" :width="dialogWidth" @close="close">
      如果未能成功唤醒游戏请查看使用说明！
      如果启动游戏卡在登录页面，请先进入游戏到大厅内再来加入房间!
      <p class="room-url">
        房间链接: <el-tag type="info">{{ fullRoomUrl }}</el-tag>
      </p>
      <template #footer>
        <el-button @click="copyLink">复制链接</el-button>
        <el-button type="primary" @click="startGame">启动游戏</el-button>
      </template>
    </el-dialog>
  </div>

  <div v-else>
    <el-dialog v-model="isVisible" title="获取房间数据成功" :width="dialogWidth" @close="close">
      <!-- 阵营选择 -->
      <el-row gutter="10" align="middle">
        <el-col :span="4">
          <h3>阵营</h3>
        </el-col>
        <el-col :span="10">
          <el-select v-model="selectedSide" placeholder="选择阵营">
            <el-option label="蓝色方" value="1"></el-option>
            <el-option label="红色方" value="2"></el-option>
          </el-select>
        </el-col>
      </el-row>

      <!-- 选择队伍的位置 -->
      <el-row gutter="1" align="middle" style="margin-top:10px;">
        <el-col :span="4">
          <h3>位置</h3>
        </el-col>
        <el-col :span="10">
          <el-select v-model="selectedSlot" placeholder="选择的位置" class="dropdown">
            <el-option v-for="n in positionCount" :key="n" :label="`${n}号位`" :value="n"></el-option>
          </el-select>
        </el-col>
      </el-row>

      <!-- 房间设置 (折叠部分) -->
      <el-collapse v-model="activeCollapse" accordion>
        <el-collapse-item title="房间设置" name="1">
          <!-- 英雄ban位设置 -->
          <el-row gutter="1" align="middle" style="margin-top:10px;">
            <el-col :span="4">
              <h3>禁用</h3>
            </el-col>
            <el-col :span="10">
              <el-select v-model="heroBan.banHerosCamp" placeholder="请选择本局禁用的英雄" style="width: 240px" multiple>
                <el-option v-for="hero in heroBan.heroList" :key="hero.id" :label="hero.name"
                  :value="hero.id"></el-option>
              </el-select>
            </el-col>
          </el-row>

          <!-- 属性设置 -->
          <el-tabs v-model="activeTab" type="border-card" class="tabs-container" style="margin-top:20px;">
            <el-tab-pane label="属性设置" name="attributes">
              <div class="setting-options">
                <div class="setting-item" v-for="(attribute, index) in attributes" :key="index"
                  @click="openDrawer(attribute)">
                  <div class="item-title">{{ attribute.name }}</div>
                  <div class="item-value">
                    {{ attribute.selectedLevel === '-1' ? '无加成' : attribute.additionLevel[attribute.selectedLevel] }}
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-collapse-item>
      </el-collapse>

      <p class="room-url">
        房间链接: <el-tag type="info">{{ fullRoomUrl }}</el-tag>
      </p>
      <template #footer>
        <el-button @click="copyLink">复制链接</el-button>
        <el-button type="primary" @click="startGame">启动游戏</el-button>
      </template>
    </el-dialog>
  </div>

  <el-notification v-if="popupMessage" :title="popupStatus" :message="popupMessage" type="info"></el-notification>

  <!-- 使用 teleport 将抽屉放到 body 下 -->
  <teleport to="body">
    <transition name="slide-up">
      <div v-if="drawerVisible" class="bottom-drawer" @touchmove.prevent="preventScroll">
        <div class="drawer-header">
          <el-button type="text" @click="closeDrawer">取消</el-button>
          <span class="drawer-title">{{ drawerData.title }}</span>
          <el-button type="primary" size="small" @click="saveDrawerSetting">确定</el-button>
        </div>
        <div class="picker-wheel" ref="drawerContent" @touchstart="startDrag" @touchmove="dragMove" @touchend="endDrag">
          <div class="options-container" ref="optionsContainer">
            <div class="picker-option" v-for="(option, index) in drawerData.options" :key="index" :class="{
              active: index === activeIndex,
              'near-active': Math.abs(index - activeIndex) === 1,
            }">
              {{ option }}
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { ElNotification, ElCollapse, ElCollapseItem, ElRow, ElCol, ElSelect, ElOption, ElTabs, ElTabPane, ElDialog, ElButton, ElTag } from "element-plus";

export default {
  props: {
    roomId: String,
    roomUrl: String,
    modelValue: Boolean,
    roomData: Object,
  },
  components: {
    ElNotification,
    ElCollapse,
    ElCollapseItem,
    ElRow,
    ElCol,
    ElSelect,
    ElOption,
    ElTabs,
    ElTabPane,
    ElDialog,
    ElButton,
    ElTag,
  },
  data() {
    return {
      activeCollapse: [], // 控制折叠项的展开与收起
      selectedSide: "1",
      customDefineItems: [],
      selectedSlot: 1,
      popupMessage: "",
      popupStatus: "",
      activeTab: "attributes",
      drawerVisible: false,
      drawerData: { title: "", options: [] },
      activeIndex: 0,
      startTouchY: 0,
      initialIndex: 0,
      optionHeight: 0,
      heroBan: {
        banHerosCamp: [],
        heroList: [
          { id: 136, name: "武则天" },
          { id: 176, name: "杨玉环" },
          { id: 121, name: "芈月" },
          { id: 141, name: "貂蝉" },
          { id: 105, name: "廉颇" },
          { id: 106, name: "小乔" },
          { id: 107, name: "赵云" },
          { id: 108, name: "墨子" },
          { id: 109, name: "妲己" },
          { id: 110, name: "嬴政" },
          { id: 111, name: "孙尚香" },
          { id: 112, name: "鲁班七号" },
          { id: 113, name: "庄周" },
          { id: 114, name: "刘禅" },
          { id: 115, name: "高渐离" },
          { id: 116, name: "阿轲" },
          { id: 117, name: "钟无艳" },
          { id: 118, name: "孙膑" },
          { id: 119, name: "扁鹊" },
          { id: 120, name: "白起" },
          { id: 123, name: "吕布" },
          { id: 124, name: "周瑜" },
          { id: 126, name: "夏侯惇" },
          { id: 127, name: "甄姬" },
          { id: 128, name: "曹操" },
          { id: 129, name: "典韦" },
          { id: 130, name: "宫本武藏" },
          { id: 131, name: "李白" },
          { id: 132, name: "马可波罗" },
          { id: 133, name: "狄仁杰" },
          { id: 134, name: "达摩" },
          { id: 135, name: "项羽" },
          { id: 139, name: "老夫子" },
          { id: 140, name: "关羽" },
          { id: 142, name: "安琪拉" },
          { id: 144, name: "程咬金" },
          { id: 146, name: "露娜" },
          { id: 148, name: "姜子牙" },
          { id: 149, name: "刘邦" },
          { id: 150, name: "韩信" },
          { id: 152, name: "王昭君" },
          { id: 153, name: "兰陵王" },
          { id: 154, name: "花木兰" },
          { id: 156, name: "张良" },
          { id: 157, name: "不知火舞" },
          { id: 162, name: "娜可露露" },
          { id: 163, name: "橘右京" },
          { id: 166, name: "亚瑟" },
          { id: 167, name: "孙悟空" },
          { id: 168, name: "牛魔" },
          { id: 169, name: "后羿" },
          { id: 170, name: "刘备" },
          { id: 171, name: "张飞" },
          { id: 173, name: "李元芳" },
          { id: 174, name: "虞姬" },
          { id: 175, name: "钟馗" },
          { id: 177, name: "成吉思汗" },
          { id: 178, name: "杨戬" },
          { id: 183, name: "雅典娜" },
          { id: 184, name: "蔡文姬" },
          { id: 186, name: "太乙真人" },
          { id: 180, name: "哪吒" },
          { id: 190, name: "诸葛亮" },
          { id: 192, name: "黄忠" },
          { id: 191, name: "大乔" },
          { id: 187, name: "东皇太一" },
          { id: 182, name: "干将莫邪" },
          { id: 189, name: "鬼谷子" },
          { id: 193, name: "铠" },
          { id: 196, name: "百里守约" },
          { id: 195, name: "百里玄策" },
          { id: 194, name: "苏烈" },
          { id: 198, name: "梦奇" },
          { id: 179, name: "女娲" },
          { id: 501, name: "明世隐" },
          { id: 199, name: "公孙离" },
          { id: 502, name: "裴擒虎" },
          { id: 197, name: "弈星" },
          { id: 503, name: "狂铁" },
          { id: 504, name: "米莱狄" },
          { id: 125, name: "元歌" },
        ]
      },
      attributes: [
        {
          key: "等级",
          name: "初始等级",
          positionData: {
            position1: ["0", "51", "56", "61", "66"],
            position2: ["28", "71", "76", "81", "86"],
          },
          additionLevel: ["4级", "5级", "8级", "10级", "12级", "15级"],
          maxLevel: 6,
          selectedPosition: "both",
          selectedLevel: "-1",
        },
        {
          key: "金币",
          name: "初始金币",
          positionData: {
            position1: ["4", "55", "60", "65", "70"],
            position2: ["32", "75", "80", "85", "90"],
          },
          additionLevel: ["1000金币", "2000金币", "5000金币", "12000金币"],
          maxLevel: 4,
          selectedPosition: "both",
          selectedLevel: "-1",
        },
        {
          key: "CD",
          name: "冷却缩减",
          positionData: {
            position1: ["3", "21", "54", "91", "59", "92", "64", "93", "69", "94"],
            position2: ["31", "47", "74", "95", "79", "96", "84", "97", "89", "98"],
          },
          additionLevel: ["减少25%", "减少40%", "减少80%", "减少99%"],
          maxLevel: 4,
          selectedPosition: "both",
          selectedLevel: "-1",
        },
        {
          key: "移速",
          name: "移速",
          positionData: {
            position1: ["106", "107", "108", "109", "110"],
            position2: ["111", "112", "113", "114", "115"],
          },
          additionLevel: ["加10%", "加20%", "加30%"],
          maxLevel: 3,
          selectedPosition: "both",
          selectedLevel: "-1",
        },
        {
          key: "法术",
          name: "法术",
          positionData: {
            position1: ["1", "52", "57", "62", "67"],
            position2: ["29", "72", "77", "82", "87"],
          },
          additionLevel: ["加10%", "加20%", "加30%"],
          maxLevel: 3,
          selectedPosition: "both",
          selectedLevel: "-1",
        },
        {
          key: "物理",
          name: "物理",
          positionData: {
            position1: ["2", "53", "58", "63", "68"],
            position2: ["30", "73", "78", "83", "88"],
          },
          additionLevel: ["加10%", "加20%", "加30%"],
          maxLevel: 3,
          selectedPosition: "both",
          selectedLevel: "-1",
        },
        {
          key: "塔伤生命",
          name: "塔伤生命(维护中)",
          positionData: {
            position1: ["13", "22", "41", "48", "14", "23"],
            position2: ["42", "49", "16", "44", "17", "45"],
          },
          additionLevel: ["加25%", "加50%", "加100%"],
          maxLevel: 3,
          selectedPosition: "both",
          selectedLevel: "-1",
        },
      ],
    };
  },
  computed: {
    fullRoomUrl() {
      return `${window.location.origin.replace('https', 'http')}?room_id=${this.roomId}`;
    },
    isVisible: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    dialogWidth() {
      return window.innerWidth <= 600 ? "90%" : "400px";
    },
    positionCount() {
      const num = this.roomData.room_data.teamerNum;
      const count = Math.ceil(num / 2);
      return count;
    },
  },
  methods: {
    applyBonuses() {
      this.customDefineItems = [];
      this.attributes.forEach((attribute) => {
        if (attribute.selectedLevel != "-1" && attribute.selectedPosition && attribute.selectedLevel) {
          const idsToApply =
            attribute.selectedPosition === "both"
              ? [...attribute.positionData.position1, ...attribute.positionData.position2]
              : attribute.positionData[attribute.selectedPosition];
          idsToApply.forEach((id) => {
            const levelNumber = parseInt(attribute.selectedLevel, 10) + 1;
            this.customDefineItems.push(`${id}:${levelNumber}`);
          });
        }
      });
      this.showNotification("成功", "所有选定加成已应用！", "success");
    },
    startGame() {
      this.applyBonuses();
      if (this.roomUrl) {
        this.showNotification("正在唤醒游戏", "游戏启动中，将在三秒内跳转", "success")
        window.location.href = this.roomUrl;
      } else {
        const roomDataTemp = this.roomData.room_data;
        roomDataTemp.campid = this.selectedSide;
        roomDataTemp.customDefineItems = this.customDefineItems;
        roomDataTemp.addpos = String(this.selectedSlot);
        roomDataTemp.firstCountDownTime = "900";
        roomDataTemp.secondCountDownTime = "15";
        roomDataTemp.banHerosCamp1 = this.heroBan.banHerosCamp;
        roomDataTemp.banHerosCamp2 = this.heroBan.banHerosCamp;
        const jsonString = JSON.stringify(roomDataTemp);
        console.log(jsonString);
        const base64Encoded = btoa(jsonString);
        const roomUrl = `tencentmsdk1104466820://?gamedata=SmobaLaunch_${base64Encoded}`;
        window.location.href = roomUrl;
      }
    },
    copyLink() {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.fullRoomUrl)
          .then(() => {
            this.$message.success("链接已复制到剪贴板！");
          })
          .catch((err) => {
            this.$message.error("复制链接失败：" + err);
          });
      } else {
        this.$message.error("复制失败,请手动复制链接!");
      }
    },
    showNotification(title, message, type) {
      ElNotification({
        title: title,
        message: message,
        type: type,
        duration: 3000,
        position: "top-right",
        showClose: true,
      });
    },
    // 抽屉相关方法
    openDrawer(attribute) {
      this.drawerData = { title: attribute.name, options: attribute.additionLevel };
      this.activeIndex = attribute.selectedLevel === "-1" ? 0 : parseInt(attribute.selectedLevel);
      this.drawerVisible = true;
      this.disableBodyScroll();
      this.$nextTick(() => {
        this.measureOptionHeight();
        this.scrollToActive();
      });
    },
    closeDrawer() {
      this.drawerVisible = false;
      this.enableBodyScroll();
    },
    saveDrawerSetting() {
      const attrIndex = this.attributes.findIndex(a => a.name === this.drawerData.title);
      if (attrIndex !== -1) {
        this.attributes[attrIndex].selectedLevel = this.activeIndex.toString();
      }
      this.closeDrawer();
    },
    measureOptionHeight() {
      const container = this.$refs.optionsContainer;
      const firstOption = container.querySelector(".picker-option");
      if (firstOption) {
        this.optionHeight = firstOption.clientHeight;
      }
    },
    scrollToActive() {
      if (this.optionHeight === 0) return;
      const container = this.$refs.optionsContainer;
      const wheel = this.$refs.drawerContent;
      const containerHeight = wheel.clientHeight;
      const offset = (containerHeight / 2) - (this.optionHeight * (this.activeIndex + 0.5)) - this.optionHeight;
      container.style.transform = `translateY(${offset}px)`;
    },
    startDrag(event) {
      this.startTouchY = event.touches[0].clientY;
      this.initialIndex = this.activeIndex;
    },
    dragMove(event) {
      if (this.optionHeight === 0) return;
      const deltaY = event.touches[0].clientY - this.startTouchY;
      const movedIndex = Math.round(-deltaY / this.optionHeight);
      let newIndex = this.initialIndex + movedIndex;
      newIndex = Math.max(0, Math.min(newIndex, this.drawerData.options.length - 1));
      this.activeIndex = newIndex;
      this.scrollToActive();
    },
    endDrag() {
      // 无需额外处理
    },
    disableBodyScroll() {
      document.body.style.overflow = "hidden";
    },
    enableBodyScroll() {
      document.body.style.overflow = "";
    },
    preventScroll(event) {
      event.preventDefault();
    },
  },
};
</script>

<style scoped>
.room-url {
  font-size: 14px;
  margin: 10px 0;
}

.custom-notification .el-notification__content {
  max-width: 90vw;
  font-size: 14px;
}

.custom-notification {
  font-size: 14px;
}

/* 属性项样式 */
.setting-options {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 10px;
}

.setting-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  cursor: pointer;
  background-color: #fff;
  transition: all 0.2s;
}

.setting-item:hover {
  background-color: #f0f0f0;
}

/* 抽屉样式 */
.bottom-drawer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 300px;
  background: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 999999;
  /* 提高层级 */
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  animation: slide-up 0.3s ease;
}

.drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: #f7f7f7;
  border-bottom: 1px solid #eee;
  position: relative;
  z-index: 10;
}

.drawer-title {
  font-size: 16px;
  font-weight: bold;
}

/* picker-wheel */
.picker-wheel {
  flex: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.options-container {
  transition: transform 0.3s ease;
}

.picker-option {
  text-align: center;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  color: #999;
  transition: all 0.3s;
  min-width: 80px;
  flex-shrink: 0;
  width: 100%;
  box-sizing: border-box;
}

.picker-option.active {
  color: #409eff;
  font-size: 18px;
  font-weight: bold;
}

.picker-option.near-active {
  color: #555;
  font-size: 17px;
}

.picker-option:not(.active):not(.near-active) {
  opacity: 0.5;
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.3s ease;
}

.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(100%);
}

/* 折叠内容的内边距 */
.el-collapse-item__content {
  padding: 10px 0;
}
</style>
