<template>
    <el-card shadow="hover" class="card">
        <h4>创建房间</h4>
        <el-row gutter="10" align="middle">
            <el-col :span="16">
                <el-select v-model="localSelectedGameMode" placeholder="选择模式" class="dropdown">
                    <el-option label="王者峡谷-排位" value="3"></el-option>
                    <el-option label="王者荣耀-匹配" value="1"></el-option>
                    <!-- <el-option label="王者荣耀-排位" value="1"></el-option> -->
                    <el-option label="墨家机关道-1v1" value="101"></el-option>
                    <el-option label="王者峡谷-开房间-1v1对抗路" value="102"></el-option>
                    <el-option label="王者峡谷-开房间-1v1中路" value="103"></el-option>
                    <el-option label="王者峡谷-开房间-1v1发育路" value="104"></el-option>
                    <el-option label="王者峡谷-开房间-5v5" value="501"></el-option>
                    <el-option label="王者峡谷-开房间-5v5随机征召" value="502"></el-option>
                    <el-option label="王者峡谷-开房间-5v5征召1ban位" value="503"></el-option>
                    <el-option label="王者峡谷-开房间-5v5征召2ban位" value="504"></el-option>
                    <el-option label="王者峡谷-开房间-5v5征召3ban位" value="505"></el-option>
                    <el-option label="王者峡谷-开房间-5v5征召4ban位" value="506"></el-option>
                    <el-option label="众星峡谷-开房间-10v10" value="510"></el-option>
                </el-select>
            </el-col>
            <el-col :span="8">
                <el-button type="success" @click="createRoom">创建房间</el-button>
            </el-col>
        </el-row>
    </el-card>
</template>

<script>
export default {
    props: {
        selectedGameMode: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            // 创建一个本地数据属性来存储 selectedGameMode 的值
            localSelectedGameMode: this.selectedGameMode,
            roomId: "",
            popupMessage: "",
            popupStatus: ""
        };
    },
    watch: {
        // 监听 localSelectedGameMode 的变化，并通过事件通知父组件
        localSelectedGameMode(newVal) {
            this.$emit('update:selectedGameMode', newVal);
        }
    },
    methods: {
        async createRoom() {
            if (this.localSelectedGameMode == "1" || this.localSelectedGameMode == "3") {
                const url = "https://www.netmsy.com/wz/api/create_room.php";
                try {
                    const response = await fetch(url, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ map_type: this.localSelectedGameMode }),
                    });
                    const data = await response.json();
                    if (data.code === 200) {
                        this.popupMessage = `房间创建成功！\n房间号：${data.roomCode}`;
                        this.popupStatus = "success";
                        this.roomId = data.room_id;
                        this.$emit("roomCreated", { success: true, roomId: data.roomCode });
                    } else {
                        this.popupMessage = `房间创建失败：${data.msg}`;
                        this.popupStatus = "error";
                        this.$emit("roomCreated", { success: false, message: data.message });
                    }
                } catch (error) {
                    this.popupMessage = `请求失败：${error.message}`;
                    this.popupStatus = "error";
                    this.$emit("roomCreated", { success: false, message: error.message });
                }
            } else {
                const url = "http://103.40.14.112:16924/create_room";
                try {
                    const response = await fetch(url, {
                        method: "POST",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ map_type: parseInt(this.localSelectedGameMode) }),
                    });
                    const data = await response.json();
                    if (data.code === 200) {
                        this.popupMessage = `房间创建成功！\n房间号：${data.room_id}`;
                        this.popupStatus = "success";
                        this.roomId = data.room_id;
                        this.$emit("roomCreated", { success: true, roomId: data.room_id });
                    } else {
                        this.popupMessage = `房间创建失败：${data.msg}`;
                        this.popupStatus = "error";
                        this.$emit("roomCreated", { success: false, message: data.msg });
                    }
                } catch (error) {
                    this.popupMessage = `请求失败：${error.message}`;
                    this.popupStatus = "error";
                    this.$emit("roomCreated", { success: false, message: error.message });
                }
            }

        }
    }
};
</script>

<style scoped>
/* 样式可以按需求进行调整 */
</style>