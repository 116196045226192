// RoomPage.vue
<template> 
  <div class="container">
    <!-- 遮罩层模块 -->
    <RoomOverlay :showOverlay="showOverlay" :roomId="roomId" @update:showOverlay="showOverlay = $event"
      @showDouYinMessage="showDouYinMessage = $event" />

    <h2>王者跨平台组队工具</h2>

    <!-- 功能介绍 -->
    <el-card shadow="hover" class="card">
      <h4>功能介绍</h4>
      <p>QQ微信跨平台组队，无时间段限制，无需卡房，将获得的房间号输入，点击加入房间即可。</p>
    </el-card>

    <!-- 加入房间 -->
    <el-card shadow="hover" class="card">
      <h4>加入房间</h4>
      <el-row gutter="10" align="middle">
        <el-col :span="16">
          <el-input v-model="roomId" placeholder="输入房间号"></el-input>
        </el-col>
        <el-col :span="8">
          <el-button type="primary" @click="joinRoom">加入房间</el-button>
        </el-col>
      </el-row>
    </el-card>

    <!-- 加入房间模态框 模块 -->
    <RoomJoin v-if="modalShow" :modelValue="modalShow" :roomId="roomId" :roomData="roomData" :roomUrl="modalRoomUrl"
      @update:modelValue="modalShow = $event" />

    <!-- 创建房间 -->
    <CreateRoom :selectedGameMode="selectedGameMode" @roomCreated="handleRoomCreated" />


    <!-- 链接跳转模块 -->
    <RoomLink v-if="false"/>

    <!-- 弹窗 -->
    <el-notification v-if="popupMessage" :title="popupStatus" :message="popupMessage" type="info"></el-notification>
  </div>
</template>

<script>
import RoomJoin from "./RoomJoin.vue";
import RoomLink from './RoomLink.vue'
import CreateRoom from "./CreateRoom.vue";
import RoomOverlay from './RoomOverlay.vue'
import { ElNotification } from "element-plus";

export default {
  components: { RoomJoin, RoomLink, CreateRoom, RoomOverlay },
  data() {
    return {
      selectedGameMode: "1",
      roomId: "",
      showPopup: false,
      popupMessage: "",
      popupStatus: "",
      showDouYinMessage: false,
      modalShow: false,
      modalRoomUrl: "",
      roomData: {},
      showOverlay: false,  // 初始为 false，依照条件显示
      linkUrl: ""  // 新增链接输入框的数据绑定
    };
  },
  computed: {
    fullRoomUrl() {
      return this.roomId ? `${window.location.origin.replace('https', 'http')}?room_id=${this.roomId}` : window.location.origin.replace('https', 'http');
    }
  },

  mounted() {
    this.checkRoomIdParam();
  },
  methods: {
    handleRoomCreated(response) {
      var popupTitle;
      if (response.success) {
        this.popupMessage = `房间创建成功！\n房间号：${response.roomId}`;
        this.roomId = response.roomId;
        this.popupStatus = "success";
        popupTitle = "创建成功";
      } else {
        this.popupMessage = `房间创建失败：${response.message}`;
        this.popupStatus = "error";
        popupTitle = "创建失败";
      }
      this.showNotification(popupTitle, this.popupMessage, this.popupStatus);
    },
    async joinRoom() {
      if (!this.roomId) {
        return this.showNotification("警告", "请输入房间号", "warning");
      }
      if (this.roomId.length == 4) {
        const url = "https://www.netmsy.com/wz/api/join_room.php";
        try {
          const response = await fetch(url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ roomCode: parseInt(this.roomId) }),
          });
          const data = await response.json();
          if (data.code === 200) {
            this.modalRoomUrl = data.gameDataURL;
            this.roomData = data; // 将获取到的数据赋值给data对象
            this.modalShow = true;
            this.showNotification("获取数据成功", `成功获取房间 ${this.roomId} 数据`, "success");
          } else {
            this.showNotification("加入房间失败", `错误信息：${data.msg}`, "error");
          }
        } catch (error) {
          this.showNotification("网络错误", `请求失败：${error.message}`, "error");
        }
      }
      if (this.roomId.length == 6) {
        const url = "http://103.40.14.112:16924/join_room";
        try {
          const response = await fetch(url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ room_id: parseInt(this.roomId) }),
          });
          const data = await response.json();
          if (data.code === 200) {
            this.modalRoomUrl = data.room_url;
            this.roomData = data; // 将获取到的数据赋值给data对象
            this.modalShow = true;
            this.showNotification("获取数据成功", `成功获取房间 ${this.roomId} 数据`, "success");
          } else {
            this.showNotification("加入房间失败", `错误信息：${data.msg}`, "error");
          }
        } catch (error) {
          this.showNotification("网络错误", `请求失败：${error.message}`, "error");
        }
      }
      
    },
    checkRoomIdParam() {
      const params = new URLSearchParams(window.location.search);
      const roomId = params.get("room_id");
      if (roomId) this.roomId = roomId;
    },
    showNotification(title, message, type) {
      ElNotification({
        title: title,
        message: message,
        type: type,
        duration: 2000,
        position: "top-right",
        showClose: true,
        offset: 10,
        customClass: "custom-notification",
      });
    },
    navigateToLink() {
      if (this.linkUrl) {
        window.location.href = this.linkUrl;
      } else {
        this.showNotification("警告", "请输入链接", "warning");
      }
    },
  },
};
</script>

<style scoped>
.container {
  font-family: "Arial", sans-serif;
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

.card {
  margin-bottom: 20px;
  margin-top: 5px;
}

/* 遮罩层样式 */
.overlay-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('@/assets/image.png') no-repeat center center;
  background-size: cover;
  /* 保证背景图充满屏幕 */
  z-index: 9999;
}

/* 自定义通知样式 */
.custom-notification .el-notification__content {
  max-width: 90vw;
  /* 设置最大宽度为视口宽度的 90%，防止通知过大 */
  font-size: 14px;
  /* 调整字体大小 */
}

.custom-notification {
  font-size: 14px;
  /* 可调节整个通知的字体大小 */
}
</style>
