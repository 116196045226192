import { createRouter, createWebHistory } from "vue-router";
import RoomPage from "@/components/RoomPage.vue";

const routes = [
  { path: "/", component: RoomPage },
];

const router = createRouter({
  history: createWebHistory(), // Vue 3 使用 createWebHistory 代替 mode: 'history'
  routes,
});

export default router;
