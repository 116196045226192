<template>
    <el-overlay v-if="overlayVisible" :mask="true">
        <div class="overlay-image"></div>
    </el-overlay>

    <!-- 抖音提示信息 -->
    <transition name="fade">
        <el-dialog v-model="showDouYinMessage" title="提示信息" center :close-on-click-modal="false" :show-close="false"
            width="80%" class="custom-dialog">
            <p>请复制链接到浏览器打开(请不要使用QQ浏览器)</p>
            <p class="room-url">
                链接: <el-tag type="info">{{ fullRoomUrl }}</el-tag>
            </p>
        </el-dialog>

    </transition>

</template>

<script>
export default {
    props: {
        showOverlay: {
            type: Boolean,
            required: true,
        },
        roomId: String,
    },
    data() {
        return {
            overlayVisible: false,  // 使用不同的变量名
            showDouYinMessage: false,
        };
    },
    mounted() {
        this.checkPlatform();
    },
    watch: {
        showOverlay(newValue) {
            this.overlayVisible = newValue;  // 监听 showOverlay prop，保持同步
        }
    },
    computed: {
        fullRoomUrl() {
            return this.roomId ? `${window.location.origin.replace('https', 'http')}?room_id=${this.roomId}` : window.location.origin.replace('https', 'http');
        }
    },
    methods: {
        checkPlatform() {
            const ua = navigator.userAgent;
            const isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            const isDouYin = ua.includes("BytedanceWebview");
            const isWeChat = ua.indexOf('MicroMessenger') > -1;
            const isQQ = ua.indexOf("MQQBrowser") > -1 || ua.indexOf("QQTheme") > -1;

            if (isWeChat || isQQ) {
                this.overlayVisible = true;  // 显示遮罩层
                this.$emit('update:showOverlay', true);  // 通过事件通知父组件
                return;
            }

            if (isiOS && isDouYin) {
                this.showDouYinMessage = true;
            }
        }
    }
};
</script>

<style scoped>
.overlay-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('@/assets/image.png') no-repeat center center;
    background-size: cover;
    z-index: 9999;
}
</style>