<template>
    <!-- 链接跳转 -->
    <el-card shadow="hover" class="card">
      <h4>链接跳转-开发者工具</h4>
      <el-row gutter="10" align="middle">
        <el-col :span="16">
          <el-input v-model="linkUrl" placeholder="输入链接"></el-input>
        </el-col>
        <el-col :span="8">
          <el-button type="primary" @click="navigateToLink">跳转链接</el-button>
        </el-col>
      </el-row>
    </el-card>
  </template>
  
  <script>
  export default {
    data() {
      return {
        linkUrl: ""  // 链接输入框的数据绑定
      };
    },
    methods: {
      navigateToLink() {
        if (this.linkUrl) {
          window.location.href = this.linkUrl;
        } else {
          this.showNotification("警告", "请输入链接", "warning");
        }
      },
      showNotification(title, message, type) {
        this.$notify({
          title: title,
          message: message,
          type: type,
          duration: 2000,
          position: "top-right",
          showClose: true,
          offset: 10,
          customClass: "custom-notification",  // 添加自定义类名
        });
      },
    },
  };
  </script>
  
  <style scoped>
  .link-redirect-container {
    font-family: "Arial", sans-serif;
    padding: 20px;
    max-width: 800px;
    margin: auto;
  }
  </style>